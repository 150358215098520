.statecharts-feature-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 120px 0 150px 0;
}

.statecharts-images-stack {
  display: flex;
  position: relative;
  gap: 0;
  margin: 0 80px 0 0;
}

.coverart_stateoutline {
  width: 130px;
  height: 130px;
  border-radius: 5px;
  margin-left: -30px;
}

.img-01 {
  transform: rotate(-10deg) translateX(-20px);
  z-index: 1;
}
.img-02 {
  transform: rotate(5deg) translateX(0px);
  z-index: 2;
}
.img-03 {
  transform: rotate(12deg) translateX(20px);
  z-index: 3;
}

.img-04 {
  transform: rotate(-5deg) translateX(20px);
  z-index: 4;
}

.statecharts-text-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.statechart-feature-headline {
  font-family: "Akira Expanded", sans-serif;
  font-size: 24px;
  color: white;
  text-align: right;
  margin: 0 0 10px 0;
}

.statechart-feature-subtext {
  font-family: "Montserrat";
  font-size: 17px;
  font-weight: 600;
  text-align: right;
  color: #b3b4ba;
  margin: 5px 0 5px 0;
}

.statechart-feature-info-text {
  display: inline-block;
  text-align: right;
  font-family: "Montserrat";
  background: linear-gradient(
    45deg,
    hsl(275deg 81% 47%) 0%,
    hsl(275deg 81% 47%) 4%,
    hsl(275deg 81% 47%) 8%,
    hsl(275deg 81% 47%) 13%,
    hsl(275deg 81% 47%) 18%,
    hsl(275deg 81% 47%) 23%,
    hsl(275deg 81% 47%) 28%,
    hsl(275deg 81% 47%) 34%,
    hsl(286deg 100% 41%) 39%,
    hsl(302deg 100% 38%) 46%,
    hsl(316deg 100% 42%) 52%,
    hsl(325deg 100% 46%) 59%,
    hsl(332deg 100% 48%) 67%,
    hsl(339deg 100% 48%) 75%,
    hsl(344deg 100% 49%) 85%,
    hsl(352deg 91% 51%) 100%
  );
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease;
  margin: 15px 0 0 0;
}

@media only screen and (max-width: 420px) {
  .statecharts-feature-container {
    flex-direction: column;
    align-items: center;
    margin: 100px 0 80px 0;
    text-align: center;
  }

  .statecharts-images-stack {
    flex-direction: row;
    margin: 0 0 20px 0;
    position: relative;
    transform: scale(0.85);
  }

  .coverart_stateoutline {
    width: 100px;
    height: 100px;
    margin-left: -20px;
    border-radius: 5px;
  }

  .img-01 {
    transform: rotate(-10deg) translateX(-10px);
    z-index: 1;
  }

  .img-02 {
    transform: rotate(5deg) translateX(0px);
    z-index: 2;
  }

  .img-03 {
    transform: rotate(12deg) translateX(10px);
    z-index: 3;
  }

  .img-04 {
    transform: rotate(-5deg) translateX(15px);
    z-index: 4;
  }

  .statecharts-text-group {
    align-items: center;
    text-align: center;
  }

  .statechart-feature-headline {
    font-size: 25px;
    margin: 0 0 8px 0;
    text-align: center;
  }

  .statechart-feature-subtext {
    font-size: 15px;
    margin: 10px 0;
    text-align: center;
  }

  .statechart-feature-info-text {
    font-size: 11px;
    padding: 8px 12px;
    margin-top: 20px;
    text-align: center;
  }
}
