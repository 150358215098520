.landing-page-container {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  background: #111111;
  margin: 0;
  font-family: "Montserrat";
  scroll-behavior: smooth;
  padding-bottom: 120px;
  transition: opacity 0.5s ease-in;
}

.appstoredownload-image {
  height: 45px;
  width: 140px;
}

.quote-section {
  width: 40%;
  margin: 60px auto 20px auto;
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jm-pic {
  border-radius: 70px;
  width: 120px;
  height: 120px;
}

.founder-quote {
  font-family: "Montserrat";
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  background: #1b1b1b;
  width: 92%;
  border-radius: 10px;
  padding: 20px;
  font-weight: 600;
}

.quote-credit {
  text-align: center;
  margin: 0;
  color: #c2c2c7;
  font-style: italic;
  font-weight: 500;
}

@media only screen and (max-width: 420px) {
  .landing-page-container {
    flex-direction: column;
    margin: 0 auto 0 auto;
    padding-bottom: 50px;
  }

  .founder-quote {
    font-size: 11px;
    width: 350px;
  }
}
