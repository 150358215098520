.page-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #111;
  flex-direction: column;
}

.manual-layout {
  width: 800px;
  height: 700px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.row {
  display: flex;
  gap: 20px;
}

.box {
  border-radius: 20px;
  border: 1px solid #5a5a5e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.top-row {
  height: 340px;
}

.bottom-row {
  height: 340px;
}

.top-left {
  width: 600px;
}

.top-right {
  width: 300px;
  padding: 20px;
}

.bottom-left {
  width: 300px;
  padding: 20px;
}

.bottom-right {
  width: 600px;
}

.recommend-icon-image,
.groupsharemusic-icon {
  color: #a665ff;
  height: 130px;
  width: 130px;
  margin: 0 0 20px 0;
}

.box-header-smallcard {
  font-family: "Akira Expanded", sans-serif;
  color: white;
  font-size: 20px;
}

.socialfeature-header {
  font-family: "Akira Expanded", sans-serif;
  font-size: 30px;
  text-align: center;
  color: #61dbc0;
}

.box-subtext-smallcard {
  font-family: "Montserrat";
  color: #dddde2;
  font-size: 14px;
  margin: 0 0 10px 0;
  font-weight: 600;
  font-style: italic;
}

.sidebyside-mockup {
  width: 350px;
  height: 250px;
}

.box-content-largecard {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.box-header-largecard {
  font-family: "Akira Expanded", sans-serif;
  color: white;
  font-size: 22px;
  margin: 0;
  color: #f94257;
}

.box-subtext-largecard {
  font-family: "Montserrat";
  color: #dddde2;
  font-size: 13px;
  margin: 0;
  font-weight: 600;
  font-style: italic;
}

.friends-image {
  width: 400px;
  height: 220px;
  border-radius: 5px;
  margin: 20px 0 0 0;
}

@media only screen and (max-width: 420px) {
  .manual-layout {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .row {
    flex-direction: column;
    gap: 20px;
  }

  .top-row,
  .bottom-row {
    height: auto;
  }

  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    width: 100%;
    padding: 15px 10px;
  }

  .sidebyside-mockup {
    width: 100%;
    height: auto;
  }

  .recommend-icon-image,
  .groupsharemusic-icon {
    height: 100px;
    width: 100px;
    margin-bottom: 15px;
  }

  .box-header-smallcard {
    font-size: 18px;
    text-align: center;
  }

  .box-subtext-smallcard {
    font-size: 13px;
    text-align: center;
  }

  .box-header-largecard {
    font-size: 20px;
    text-align: center;
  }

  .box-subtext-largecard {
    font-size: 13px;
    text-align: center;
  }

  .box {
    flex-direction: column;
    text-align: center;
  }

  .box-content-largecard,
  .box-content-smallcard {
    padding: 10px;
  }

  .friends-image {
    width: 100%;
    height: auto;
    margin-top: 15px;
    border-radius: 5px;
  }

  .socialfeature-header {
    font-size: 22px;
    width: 100%;
    padding: 0 10px;
  }
}
