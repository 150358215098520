.landing-header-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.landing-toolbar {
  background: #1b1b1d;
  width: 700px;
  padding: 10px;
  border-radius: 5px;
  margin: 0 auto 0 auto;
  display: flex;
  margin: 20px 0 0 0;
  justify-content: space-between;
}

.landing-toolbar-link {
  font-family: "Druk Wide Bold", sans-serif;
  font-size: 12px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.landing-login-link {
  color: #b649f9;
}

.landing-register-link {
  color: #24dc2d;
}

.landing-mobile-link {
  color: #f7004e;
}

.landing-login-link:hover {
  color: #d18ffa;
}

.landing-register-link:hover {
  color: #81fa87;
}

.landing-mobile-link:hover {
  color: #ec5283;
}

.landing-toolbar-link:hover {
  cursor: pointer;
}

.landing-header-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0 0 0;
}

.landing-header-logo {
  height: 90px;
  margin: 0 0 0 0;
}

.landing-augio-header-text {
  font-family: "BorisBlackBloxx", sans-serif;
  font-size: 68px;
  letter-spacing: 13px;
  margin: 0;
}

.landing-page-header-subtext {
  font-family: "Montserrat";
  color: grey;
  font-size: 19px;
  margin: 0;
}

.map-outline-container {
  position: relative;
  width: 700px;
  height: 400px;
  display: flex;
  justify-content: center;
}

.map-outline {
  width: 100%;
  height: auto;
}

.map-overlay-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Druk Wide Bold", sans-serif;
  font-size: 30px;
  text-align: center;
  color: #c8c8ce;
  z-index: 1;
  pointer-events: none;
}

.landing-header-about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-header-secondary {
  font-family: "Druk Wide Bold", sans-serif;
  font-size: 30px;
  text-align: center;
  color: #c8c8ce;
}

.landing-header-info-text {
  color: #a5a5aa;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  margin: 0 auto 40px;
  text-align: center;
  width: 570px;
  margin: 70px 0 80px 0;
}

.landing-header-about-text {
  font-family: "Akira Expanded", sans-serif;
  font-size: 30px;
  text-align: center;
  width: 800px;
  margin: 0 auto 0 auto;
  background-image: linear-gradient(
    10deg,
    hsl(275deg 81% 47%) 0%,
    hsl(275deg 81% 47%) 10%,
    hsl(275deg 81% 47%) 20%,
    hsl(275deg 81% 47%) 31%,
    hsl(275deg 81% 47%) 42%,
    hsl(292deg 100% 39%) 53%,
    hsl(316deg 100% 42%) 65%,
    hsl(330deg 100% 47%) 77%,
    hsl(340deg 100% 49%) 89%,
    hsl(352deg 91% 51%) 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media only screen and (max-width: 420px) {
  .landing-toolbar {
    width: 370px;
  }

  .landing-header-logo {
    height: 60px;
    width: 300px;
    margin: 0 0 0 0;
  }

  .map-outline-container {
    margin: 20px auto 0 auto;
    height: 360px;
  }

  .map-outline {
    width: 410px;
    height: 265px;
  }

  .map-overlay-text {
    top: 30%;
    font-size: 17px;
  }

  .landing-header-info-text {
    margin: 0 auto 50px auto;
    font-size: 15px;
    width: 360px;
  }

  .landing-header-about-text {
    font-size: 25px;
    margin: 20px auto 20px auto;
    width: 360px;
  }
}
