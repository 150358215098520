.augio-region-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 90px auto 0 auto;
  background: #151515;
  width: 1000px;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.augio-region-fade-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.augio-region-app-image {
  height: 450px;
  width: 250px;
  object-fit: contain;
  flex-shrink: 0;
}

.augio-region-text-group {
  margin-left: 40px;
  text-align: center;
}

.augio-map-image {
 width: 160px;
  height: 160px; 
  object-fit: cover;
  object-position: center; 
  border-radius: 8px; 
}

.augio-region-info-title {
  font-family: "Akira Expanded", sans-serif;
  font-size: 24px;
  margin: 10px 0 5px 0;
  color: white;
}

.augio-region-info-header {
  font-family: "Montserrat";
  font-size: 17px;
  color: #a2a1a9;
  margin: 0 0 10px 0;
}

.augio-region-info-subtext {
  font-family: "Montserrat";
  font-size: 12px;
  margin: 0;
  color: white;
}


@media only screen and (max-width: 420px) {
  .augio-region-info-container {
    flex-direction: column;
    width: 355px;
    padding: 20px 15px 30px 15px;
    margin: 50px auto 0 auto;
    border-radius: 10px;
  }

  .augio-region-fade-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .augio-region-app-image {
    height: 300px;
    width: 180px;
  }

  .augio-region-text-group {
    margin-left: 0;
    text-align: center;
  }

  .augio-map-image {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }

  .augio-region-info-title {
    font-size: 20px;
  }

  .augio-region-info-header {
    font-size: 15px;
  }

  .augio-region-info-subtext {
    font-size: 12px;
  }
}
