.mobile-app-preview-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subheader-text-small {
    font-family: "Akira Expanded", sans-serif;
  font-size: 30px;
  text-align: center;
  color: #61b6db;
}

.subheader-text {
  font-family: "Akira Expanded", sans-serif;
  font-size: 30px;
  text-align: center;
  color: #61dbc0;
}

.carousel-outer {
  width: 100%;
  max-width: 1300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-3up-track {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  transition: transform 0.6s ease-in-out;
  animation: slideIn 0.6s ease-in-out;
}

.landing-page-mobile-app-download-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 40px 0;
}

.landing-header-app-download-text {
     font-family: "Akira Expanded", sans-serif;
     color: white;
}

@keyframes slideIn {
  0% {
    transform: translateX(50px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.carousel-3up {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  transition: all 0.6s ease-in-out;
}

.carousel-3up-item {
  position: relative;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-title-text {
  font-family: "Druk Wide Bold", sans-serif;
  font-size: 17px;
  color: white;
}

.feature-subtext {
  font-family: "Montserrat";
  font-size: 14px;
  color: white;
}

.carousel-3up-item img {
  border-radius: 10px;
  object-fit: cover;
  transition: all 0.4s ease;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.carousel-3up-item.side img {
  width: 300px;
  height: 200px;
  opacity: 0.6;
}

.carousel-3up-item.center img {
  width: 420px;
  height: 285px;
  opacity: 1;
  z-index: 2;
}

.image-wrapper {
 position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  border-radius: 10px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius: 10px;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.feature-info {
  margin-top: 20px;
  max-width: 600px;
  text-align: center;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  cursor: pointer;
  color: white;
}

.carousel-arrow {
  font-size: 2rem;
  cursor: pointer;
  background: #1c1c1c;
  border-radius: 5px;
}

@media only screen and (max-width: 420px) {

.carousel-outer {
  width: 100%;
  max-width: 1300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-3up-track {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  transition: transform 0.6s ease-in-out;
  animation: slideIn 0.6s ease-in-out;
}

.landing-page-mobile-app-download-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 40px 0;
}


.carousel-3up {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  transition: all 0.6s ease-in-out;
}

.carousel-3up-item {
  position: relative;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-title-text {
  font-family: "Druk Wide Bold", sans-serif;
  font-size: 17px;
  color: white;
}

.feature-subtext {
  font-family: "Montserrat";
  font-size: 13px;
  color: white;
}

.carousel-3up-item img {
  border-radius: 10px;
  object-fit: cover;
  transition: all 0.4s ease;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.carousel-3up-item.side img {
  width: 300px;
  height: 200px;
  opacity: 0.6;
}

.carousel-3up-item.center img {
  width: 400px;
  height: 285px;
  opacity: 1;
  z-index: 2;
}

.image-wrapper {
 position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  border-radius: 10px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius: 10px;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.feature-info {
  margin-top: 20px;
  max-width: 600px;
  text-align: center;
}
}
