.trending-charts-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0 20px 0;
}

.trending-chart-item {
  margin: 0 30px 0 0;
}

.trending-chart-item:hover {
  cursor: pointer;
}

.trending-chart-image {
  position: relative;
  width: 125px;
  height: 125px;
}

.chart-cover-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;

}

.chart-cover-art {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
  opacity: 0.4;
}

.state-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
}

.trending-chart-name {
  font-family: "Montserrat";
  font-weight: 600;
  color: grey;
  font-size: 12px;
  margin: 3px 0 0 0;
  color: light-dark(black, #C2C2C7);
}

@media only screen and (max-width: 420px) {
  .trending-charts-container {
    overflow-x: auto;
    width: 365px;
  }

  .trending-chart-image {
    height: 120px;
    width: 120px;
  }

}
