.page-container {
  margin: 0;
  padding: 0;
  display: flex; /* Ensure this container is flex to handle the layout */
  flex-direction: column; /* Stack toolbar, content, and audio player */
}

.inner-page-layout {
  display: flex;
  flex-direction: row;
  padding-top: 70px; /* Adjust this value based on your toolbar's height */
  padding-bottom: 90px; /* Adjust this value based on your audio player's height */
  width: 100%; /* Ensure this takes the full width */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.inner-page-content {
  display: flex;
  flex-direction: column;
  margin: 40px 0 60px 220px;
  overflow-y: auto;
  flex-grow: 1; /* Let it take up available space */
}

.header-titles {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  padding: 0 0 10px 0;
  color: light-dark(black, #c2c2c7);
}

.filter-btn {
  font-family: "Montserrat";
  font-weight: 500;
  background: none;
  border: 1px solid #dddde2;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0;
  color: black;
}

.filter-btn:hover {
  background: #dddde2;
  cursor: pointer;
}

.filter-btn.selected {
  background-color: #dddde2;
  color: black;
}

.info-message-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: light-dark(#e7dded, #14011e);
  border: 1px solid light-dark(#8840b7, #5b1887);
  border-radius: 5px;
  width: 900px;
  height: 40px;
  padding: 0 10px 0 10px;
  margin: 0 0 20px 0;
}

.info-message-text {
  font-family: "Montserrat";
  font-weight: 500;
  color: light-dark(#320152, #ca9ee8);
  font-size: 14px;
}

.info-message-close-btn {
  color: light-dark(#320152, #ca9ee8);
  cursor: pointer;
  font-size: 14px;
}

.info-upload-message-container {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: light-dark(#edebdd, #241f01);
  border: 1px solid light-dark(#b7b540, #877818);
  border-radius: 5px;
  width: 900px;
  height: 40px;
  padding: 0 10px 0 10px;
  margin: 0 0 20px 0;
}

.info-upload-message-text {
  font-family: "Montserrat";
  font-weight: 500;
  color: light-dark(#524b01, #e8df9e);
  font-size: 14px;
}

.info-upload-message-close-btn {
  color: light-dark(#524b01, #e8df9e);
  cursor: pointer;
  font-size: 14px;
}

@media only screen and (max-width: 420px) {
  .inner-page-content {
    margin-left: 20px;
    margin-top: 25px;
  }

  .header-titles {
    font-size: 24px;
  }

  .info-message-container {
    width: 350px;
  }

  .info-message-text,
  .info-upload-message-text {
    font-size: 10px;
  }

  .info-upload-message-container {
    width: 350px;
    display: flex;
  }
}
