.upload-form-container {
  font-family: "Montserrat";
  position: fixed;
  top: 30%; 
  left: 50%;
  transform: translate(
    -50%,
    -30%
  ); 
  width: 775px; 
  max-height: 750px; 
  overflow-y: scroll; 
  background: light-dark(white, #191919); 
  padding: 30px;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  z-index: 1300; 
  display: none; 
  transition: all 0.3s ease-in-out; 
}

.upload-form-container.show {
  display: block; 
}

.upload-form {
  display: flex;
  flex-direction: column;
}

.upload-form-top-rows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.track-upload-container {
  margin-bottom: 20px; 
}

.cover-art-box {
  background: light-dark( #fcfcfd, #484849);
  width: 150px;
  height: 150px;
  border: 1px solid light-dark(#e4e4e6, #9d9da0);
  margin-bottom: 10px; 
}

.add-cover-art-btn {
  font-family: "Montserrat";
  font-weight: 600;
  background: #dddde2;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 10px;
  padding: 5px;
  width: 150px;
  display: inline-block;
  text-align: center;
  margin: 0 0 8px 0;
  color: black;
}

.add-cover-art-btn:hover { 
  background: #bdbdc1;
}

.upload-form-add-audio-btn {
  font-family: "Montserrat";
  font-weight: 600;
  background: #dddde2;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 10px; 
  padding: 8px;
  width: 715px;
  display: inline-block;
  text-align: center;
  margin: 10px 0 12px 0;
  color: black;
}

.upload-form-add-audio-btn:hover {
  background: #bdbdc1;
}

.save-upload-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 80px;
  height: 30px;
  background: #0091ff;
  color: white;
  border: none;
  border-radius: 5px;
}

.save-upload-btn:hover {
  cursor: pointer;
  background: #0175ce;
}

.close-upload-form-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 80px;
  height: 30px;
  background: #bababe;
  color: black;
  border: none;
  border-radius: 5px;
}

.close-upload-form-btn:hover {
  cursor: pointer;
  background: #97979b;
}

.form-rows-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 5px 20px;
}

.upload-form-input-group {
  display: flex;
  flex-direction: row;
}

.upload-form-input-group-custom {
    display: flex;
  flex-direction: column;
}

.upload-label-input-group {
  display: flex;
  flex-direction: column;
}

.upload-form-btn-group {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.upload-form-label {
  font-weight: 500;
  font-size: 13px;
}

.upload-form-input {
  margin: 7px 10px 10px 0;
  background-color:light-dark(#fcfcfd, #1d1d1d);
  border: 1px solid light-dark(#e8eaed, #333334);
  border-radius: 4px;
  color: light-dark(#3d3d40, white);
  width: 260px;
  height: 32px;
  outline: none;
  font-size: 15px;
  font-family: "Noto Sans", sans-serif;
}

.upload-form-input-custom {
   margin: 7px 10px 10px 0;
  background-color:light-dark(#fcfcfd, #1d1d1d);
  border: 1px solid light-dark(#e8eaed, #333334);
  border-radius: 4px;
  color: light-dark(#3d3d40, white);
  width: 530px;
  height: 32px;
  outline: none;
  font-size: 15px;
  font-family: "Noto Sans", sans-serif;
}

.upload-form-input::placeholder, .upload-form-input-album::placeholder, .upload-form-input-custom::placeholder {
  color: #6f6f76;
  font-family: "Noto Sans", sans-serif;
  font-size: 11px;
}

.upload-form-input-album {
   margin: 7px 10px 10px 0;
  background-color:light-dark(#fcfcfd, #1d1d1d);
  border: 1px solid light-dark(#e8eaed, #333334);
  border-radius: 4px;
  color: light-dark(#3d3d40, white);
  width: 350px;
  height: 32px;
  outline: none;
  font-size: 15px;
  font-family: "Noto Sans", sans-serif;
}

.upload-form-file {
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 0;
  color: black;
}

.upload-form-file-album {
  display: flex;
  flex-direction: row;
  margin: 5px 0 10px 0;
}

.upload-form-file-icon {
  color: #808083;
}

.upload-form-filename {
  font-size: 14px;
  font-weight: 500;
  color: #808083;
  margin: 0 0 0 5px;
}

.upload-form-delete-file-btn {
  background: none;
  border: none;
}

.upload-form-trash-icon {
  color: #808083;
  margin: 0 0 0 20px;
}

.upload-form-trash-icon:hover {
  cursor: pointer;
}

.upload-form-trash-icon:hover {
  cursor: pointer;
  color: red;
}

.drag-drop-area {
  border: 2px dashed #dddde2;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 20px 0 20px 0;
  transition: all 0.3s ease-in-out;
  color: #b6b6bc;
}

.drag-drop-area.dragging {
  border-color: #0091ff;
  background-color: #f2faff; /* Lighter background to indicate active drag */
  box-shadow: 0px 2px 4px rgba(0, 145, 255, 0.2); /* Soft shadow for depth */
  color: #007bff; /* Text color change for better visibility */
}

.drag-drop-area-text {
  margin: 0;
}

.upload-form-divider {
  color: #f3f3f7;
  width: 100%;
  opacity: 0.4;
  margin: 13px 0 13px 0;
}

.date-picker-container {
  margin: 7px 10px 10px 0;
  background-color: rgb(252, 252, 253);
  border: 1px solid rgb(232, 234, 237);
  border-radius: 4px;
  color: #3d3d40;
  width: 350px;
  height: 40px;
  outline: none;
  font-size: 15px;
  display: flex;
  align-items: center;
}

/* Customize the input field of the date picker */
.date-picker-container input[type="text"] {
  border: none; /* Remove the border */
  background: transparent; /* Optionally, make the background transparent */
  outline: none; /* Remove the outline on focus */
  flex: 1;
  padding: 0 16px;
}

/* Remove the border around DatePicker input */
.date-picker-container
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-input {
  border: none !important;
}

/* Remove the outline on focus for DatePicker input */
.date-picker-container
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-input:focus {
  outline: none !important;
}

/* Customize the label of the date picker */
.date-picker-container .MuiFormControl-root .MuiInputLabel-root {
  color: #3d3d40;
}

/* Customize the date picker dropdown icon */
.date-picker-container
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-root
  .MuiSvgIcon-root {
  color: #6f6f76;
}

.error-message {
  padding-bottom: 10px;
  color: red;
  font-size: 10px;
  font-weight: 500;
}

.upload-terms-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
}

.upload-terms-text {
  font-weight: 500;
  font-size: 11px;
  color: grey;
}
