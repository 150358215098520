.sidenavigation-container {
  height: auto;
  width: 190px;
  background: light-dark(#ffffff, #19191a);
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  box-shadow: 1px 0 4px 1px rgb(0 0 0 / 6%);
  border-radius: 9px;
  padding: 10px;
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 500;
  overflow-y: auto;
}

.sidenavigation-item {
  display: flex;
  align-items: center;
  gap: 8px; 
  padding: 3px 0 3px 3px;
  font-size: 13px;
  margin: 5px 0 5px 0;
  font-weight: 600;
  transition: all 0.3s ease;
}

.sidenavigation-playlist {
   display: flex;
  align-items: center;
  gap: 8px; 
  padding: 3px 0 3px 3px;
  font-size: 13px;
  margin: 2px 0 2px 0;
  font-weight: 600;
  transition: all 0.3s ease;
}

.sidenavigation-link {
  text-decoration: none;
  color: light-dark( gray, #a6a6ac);
  font-weight: 500;
  display: block; 
}

.sidenavigation-link-header {
  display: flex;
  align-items: center;
  margin: 10px 0 0 2px;
  font-weight: 700;
  color: light-dark( gray, #a6a6ac);
  font-size: 14px;
}

.chevron-icon {
  margin: 0 0 0 12px;
  font-size: 15px;
}

.sidenavigation-link:hover {
  color: light-dark( black, white);
  transition: 0.3 all;
  cursor: pointer;
}

.sidenavigation-icon,
.sidenavigation-item svg {
  font-size: 16px; 
}

.sidenavigation-text-icon {
  display: flex;
  align-items: center;
  gap: 5px; /* space between text and icon */
  color: gray;
  font-weight: 600;
  cursor: pointer; 
}

.sidenavigation-text-icon span {
  flex: 1; /* text takes the remaining space */
}

.sidenavigation-text {
  color: gray;
  font-weight: 600;
}

.sidenavigation-divider {
  width: 170px;
  color: #808083;
  opacity: 30%;
}

.playlists-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.add-playlist-btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  margin: 3px 0 0 2px;
}

.add-playlist-icon {
  margin: 0 5px 0 0;
}

.sidenavigation-link.active,
.sidenavigation-icon.active-icon {
  color: black;
  font-weight: 700;
  background: light-dark(#ebebee, #9e9ea1);
  border-radius: 4px;
}

@media only screen and (max-width: 420px) {
  .sidenavigation-container {
    display: none;
  }

  .sidenavigation-container.open {
    display: block; 
  }
}
