.upload-selection-modal {
    font-family: 'Montserrat';
    position: fixed; 
    top: 40%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 380px; 
    height: auto; 
    background: light-dark(white, #242323); 
    padding: 30px; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    z-index: 1000; 
    display: none; 
    transition: all 0.3s ease-in-out; 
}

.upload-selection-modal.show {
    display: block; 
}

.uploadselectionmodal-header {
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    margin: 0 0 15px 0;
}

.uploadselectionmodal-card {
    font-family: 'Montserrat';
    font-weight: 600;
    border: none;
    background: light-dark(#ebebee, #bcbcbe);
    color: black;
    border-radius: 10px;
    margin: 0 20px 0 0;
    width: 100px;
    height: 50px;
}

.uploadselectionmodal-card:hover {
    cursor: pointer;
    background: #dfdfe4;
}

.button-container {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    gap: 20px; 
}


.uploadselectionmodal-card {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    gap: 8px; 
}

.button-content {
    display: flex; 
    align-items: center; 
    justify-content: center; 
}

.upload-icon {
    font-size: 20px;
    margin: 0 3px 0 0;
}
