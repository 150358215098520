.create-account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  padding: 70px 0 0 0;
}

.create-account-logo {
  height: 53px;
  width: 262px;
  margin: 0 auto 20px auto;
}

.create-account-form {
  display: flex;
  flex-direction: column;
  background: #171717;
  justify-content: center;
  width: 600px;
  padding: 35px 55px;
  border-radius: 14px;
  box-shadow: 7px 7px 18px 1px rgba(0, 0, 0, 0.056);
  position: relative;
}

.signup-text {
  font-size: 20px;
  margin: 0 0 30px 0;
  font-weight: 600;
  text-align: center;
  color: white;
}

.create-account-input {
  margin: 7px 10px 10px 0;
  background-color: #1d1d1d;
  border: 1px solid #333334;
  border-radius: 4px;
  color: white;
  width: auto;
  height: 40px;
  outline: none;
  font-size: 15px;
  font-family: "Noto Sans", sans-serif;
}

.dropdown-input {
  width: 98%;
}

.form-input-group {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.form-input-row {
  display: flex;
  flex-direction: row;
}

.create-account-input::placeholder {
  color: #6f6f76;
}

.create-account-submit {
  font-family: "Montserrat";
  font-size: 14px;
  color: white;
  background: #7300bb;
  border-radius: 4px;
  font-weight: 600;
  padding: 9px;
  border: none;
  margin: 13px 0 0 0;
  width: 480px;
  margin: 20px 0 0 0;
}

.create-account-submit:hover {
  cursor: pointer;
  background: #5f0199;
}

.agree_to_terms {
  width: 200px;
  font-size: 10px;
  text-align: justify;
}

.error-message {
  padding-bottom: 10px;
  color: red;
  font-size: 10px;
  font-weight: 500;
}

.create-account-form-helper-text-group {
  padding: 0 0 0 15px;
}

.helper-text {
  font-size: 12px;
  width: 250px;
  color: #545457;
  margin: 5px 0 0 0;
}

.agree-terms-link {
  color: white;
}

.create-account-form-label {
  /*margin-top: 8px;*/
  font-size: 13px;
  font-weight: 500;
  color: white;
}

.regions-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.region-container {
  text-align: left;
  width: 110px;
  margin: 0 10px 15px 0;
}

.region-image-container {
  background-color: black;
  padding: 5px;
  display: inline-block;
  border-radius: 3px;
  width: 110px;
  height: 110px;
  margin-bottom: 5px;
}

.region-image-register-form {
  width: 100px;
  height: 100px;
  display: block;
  object-fit: cover;
}

.region-name-register-form {
  margin: 0;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.terms-and-condition {
  text-align: start;
}

.check-box-style {
  width: 20px;
  margin: 0px;
  height: 14px;
}

.agree-to-terms {
  font-size: 14px;
  width: 250px;
}

.agree-to-terms-text {
  color: #545457;
  font-size: 11px;
  width: 550px;
}

.passwordBtn {
  position: absolute;
  right: 20px;
  top: 17px;
  cursor: pointer;
  color: #c4c4ca;
  width: 20px;
}

.passwordContainer {
  position: relative;
}

.checkbox {
  color: black;
}

.register-terms-agreement-text {
  font-size: 9px;
  width: 480px;
  color: white;
}

@media screen and (max-width: 500px) {
  .create-account-form {
    width: 365px;
    padding: 15px 35px;
  }

  .create-account-container {
    padding: 30px 0 70px 0;
  }

  .signup-text {
    font-size: 17px;
  }

  .dropdown-input {
    width: 98%;
  }

  .form-input-row {
    flex-direction: column;
  }

  .passwordBtn {
    right: 23px;
  }

  .agree-to-terms-text {
    width: 260px;
  }

  .create-account-submit {
    width: auto;
    margin: 20px 0 20px 0;
  }

  .register-terms-agreement-text {
    width: 300px;
  }
}
