.chart-page-container {
  font-family: "Montserrat";
}

.chart-banner {
   background: light-dark( #1a1a1a, #19191a);
  width: 900px;
  height: 200px;
  border-radius: 7px;
  padding: 1px 20px 5px 20px;
}

.chart-details-spacing {
  margin: 15px 0 5px 0;
}

.chart-name {
  color: white;
  font-size: 22px;
}

.chart-description {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.chart-fans {
  color: #dddde2;
  font-weight: 400;
  font-size: 14px;
}

.chart-content-container {
  margin: 40px 0 0 0;
}

.chart-favorite-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Adjust the gap between the icon and the text */
  font-family: "Montserrat";
  font-weight: 600;
  padding: 5px 8px;
  border: none;
  border-radius: 3px;
  background: #dddde2;
  margin: 35px 0 0 0;
  color: black;
}

.chart-favorite-btn:hover {
  cursor: pointer;
  background: #bcbcc1;
}

.chart-randomize-track-btn {
  font-family: "Montserrat";
  font-weight: 600;
  color: white;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  background: #3f007c;
  border: none;
  margin: 20px 0 15px 0;
}

.chart-randomize-track-btn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 420px) {
  .chart-banner {
    width: 365px;
    height: 165px;
  }

  .chart-name {
    font-size: 20px;
  }

  .chart-description {
    font-size: 16px;
  }

  .chart-fans {
    font-size: 11px;
  }

  .chart-favorite-btn {
    margin-top: 15px;
  }
}
