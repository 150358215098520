.faq-container {
  text-align: center;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0e0e0e;
  margin: 0 0 50px 0;
}

.faq-title-text {
  color: white;
  margin: 60px 0 0 0;
}

.faq-subtitle-text {
  color: #9b9b9f;
  font-weight: 400;
  font-size: 16px;
}

.faq-search-input {
  width: 470px;
  height: 32px;
  font-family: "Montserrat";
  background-color: #222121;
  padding: 8px 50px 8px 10px;
  outline: none;
  border: none;
  color: white;
  border-radius: 3px;
}

.faq-list {
  margin-top: 20px;
  width: 700px;
}

.faq-item {
  border-bottom: 1px solid #515153;
  padding: 10px;
  cursor: pointer;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: white;
}

.arrow-icon {
  transition: transform 0.3s ease-in-out;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.faq-description {
  margin-top: 5px;
  color: #9b9b9f;
  text-align: left;
}

@media only screen and (max-width: 420px) {

.faq-search-input {
  width: 330px;
}

.faq-subtitle-text {
  font-size: 12px;
}

.faq-list {
  width: 330px;
}

}