.trending-regions-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0 20px 0;
}

.trending-region-item {
  margin: 0 30px 0 0;
}

.trending-region-item:hover {
  cursor: pointer;
}

.trending-region-image {
  position: relative;
  width: 125px;
  height: 125px;
  cursor: pointer;
}

.region-cover-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
}

.region-cover-art {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
  opacity: 0.4;
}

.region-cover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
}

.trending-region-name {
  font-family: "Montserrat";
  font-weight: 600;
  color: grey;
  font-size: 12px;
  margin: 3px 0 0 0;
  color: light-dark(black, #c2c2c7);
  max-width: 125px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 420px) {
  .trending-regions-container {
    overflow-x: auto;
    width: 365px;
  }

  .trending-region-image {
    height: 120px;
    width: 120px;
  }
}
