footer {
  background: #151515;
  height: 300px;
  width: 100%;
  bottom: 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-size: 12px;
  padding: 25px 0 15px 0;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.footer-text-logo {
  height: 30px;
  width: 150px;
}

.footer-top-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 0 20px 0;
}

.footer-bottom-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 20px 0 0 0;
}

.footer-company-resources {
  display: flex;
  flex-direction: row;
}

.footer-links-container {
  display: flex;
  flex-direction: column;
}

.footer-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: end;
  margin: 0 0 0 0;
}

.footer-social-gorup {
  display: flex;
  flex-direction: column;
  margin: 0 50px 0 0;
}

.footer-divider {
  width: 1200px;
}

.footer-text-spacing {
  padding: 3px 0 3px 0;
}

.footer-button-link {
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
}

.footer-button-link:hover {
  cursor: pointer;
}

.fbl-login {
  color: #a04ce9;
  margin: 0 5px 0 0;
}

.fbl-register {
  color: #0fac17;
  margin: 0 5px 0 5px;
}

.fbl-mobile {
  color: #f50b44;
  margin: 0 0 0 5px;
}

.contact-email-footer {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  display: flex;
}

.footer-links-group {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px 0;
  text-align: left;
}

h6 {
  margin: 0;
  color: #a04ce9;
  font-size: 15px;
  padding: 0 0 8px 0;
}

.footer-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 7px 0;
  margin: 0;
}

.augio-footer-tranparent-g {
  height: 90px;
  width: 90px;
  position: absolute;
  left: 0;
  display: flex;
}

.clickable-link {
  padding: 0;
}

.clickable-link:hover,
.quick-link:hover {
  text-decoration: underline;
}

.footer-link:active {
  color: #ffffff;
}

.footer-link:visited {
  color: #ffffff;
}

.footer-icon-link:visited {
  color: #ffffff;
}

.footer-social-text {
  color: #ffffff;
  padding: 0 0 0 0;
}

.footer-icon-group {
  display: flex;
  flex-direction: column;
  /*margin: 2px 40px 15px 0;*/
}

.social-link-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-link-name {
  font-size: 10px;
  font-weight: 600;
  margin: 0 0 0 0;
}

.footer-icon {
  font-size: 30px;
  cursor: pointer;
  margin: 0 3px 0 3px;
  color: white;
}

.footer-icon:hover {
  color: #8a01db;
  cursor: pointer;
  transition: all 0.3s;
}

.augio-copyright {
  text-align: center;
  background: linear-gradient(
    45deg,
    hsl(275deg 81% 47%) 0%,
    hsl(275deg 81% 47%) 4%,
    hsl(275deg 81% 47%) 8%,
    hsl(275deg 81% 47%) 13%,
    hsl(275deg 81% 47%) 18%,
    hsl(275deg 81% 47%) 23%,
    hsl(275deg 81% 47%) 28%,
    hsl(275deg 81% 47%) 34%,
    hsl(286deg 100% 41%) 39%,
    hsl(302deg 100% 38%) 46%,
    hsl(316deg 100% 42%) 52%,
    hsl(325deg 100% 46%) 59%,
    hsl(332deg 100% 48%) 67%,
    hsl(339deg 100% 48%) 75%,
    hsl(344deg 100% 49%) 85%,
    hsl(352deg 91% 51%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 20px 0 0 0;
}

@media only screen and (max-width: 420px) {
  .footer-links-group {
    padding: 0 20px 20px 20px;
  }

  .augio-footer-tranparent-g {
    display: none;
    margin: 0;
    padding: 0;
  }

  .footer-container {
    max-width: 420px;
  }

  .footer-divider {
    width: 390px;
  }

  .footer-top-group {
    max-width: 390px;
    margin: 10px 0 5px 0;
  }

  .footer-bottom-group {
    flex-direction: column;
    align-items: center;
  }

  .augio-copyright {
    margin: 10px 0 10px 0;
  }

  .footer-text-logo {
    height: 22px;
  width: 104px;
  }
}
