.account-details-container {
  margin: 0;
  padding: 0;
  display: flex; /* Ensure this container is flex to handle the layout */
  flex-direction: column; /* Stack toolbar, content, and audio player */
  min-height: 100vh;
  height: 100%;
}

.account-details-form {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  background: light-dark(white,#171717);
  width: 800px;
  height: auto;
  margin: 110px auto 0 auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 7px 7px 18px 1px rgba(0, 0, 0, 0.056);
}

.account-details-header {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 0 0;
}

.account-details-subheader {
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 0 0;
  color: #808083;
}

.account-details-save-msg {
  display: none;
  color: green;
  margin: 10px 0 0 0;
  background: lightgreen;
  width: 115px;
  padding: 4px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
}

.avatar-settings-group {
  display: flex;
  flex-direction: row;
  margin: 10px 0 20px 0;
  align-items: center;
}

.avatar-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden; /* Ensures the image does not overflow the circular shape */
  display: flex; /* These two lines center the image within the div */
  justify-content: center;
  align-items: center;
}

.default-avatar {
  height: 70px;
  width: 70px;
  color: #dddde2;
}

.avatar-header {
  font-weight: 600;
  font-size: 17px;
  margin: 0;
}

.avatar-subheader {
  font-weight: 600;
  font-size: 10px;
  margin: 0;
  color: #808083;
}

.avatar-add-image-btn {
  background: #dddde2;
  font-weight: 600;
  font-size: 12px;
  margin: 15px 0 0 0;
  border-radius: 5px;
  border: none;
  height: 23px;
  padding: 4px 8px 4px 8px;
  color: black;
}

.avatar-add-image-btn:hover {
  cursor: pointer;
}

.file-input-container {
  position: relative;
  margin: 10px 0 0 0;
}

.avatar-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.save-avatar-btn {
  border: none;
  background: #0091ff;
  padding: 4px;
  width: auto;
  border-radius: 3px;
  font-family: "Montserrat";
  color: white;
  font-weight: 600;
}

.avatar-info-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
}

.account-details-divider {
  width: auto;
  margin: 15px 0 10px 0;
  color: #c2c2c7;
  opacity: 0.2;
}

.input-label-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 10px 0 0;
}

.account-details-form-label {
  font-weight: 500;
  font-size: 13px;
}

.account-details-form-input {
  margin: 7px 10px 10px 0;
  background-color: light-dark( rgb(252, 252, 253), #1d1d1d);
  border: 1px solid light-dark(rgb(232, 234, 237), #333334);
  border-radius: 4px;
  color: light-dark(#3d3d40, #ffffff);
  width: 100%;
  height: 40px;
  outline: none;
  font-size: 15px;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}

.non-editable-input {
  color: #aaaaaf;
}

.account-details-btn-group {
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 auto;
  justify-content: flex-end;
  width: 100%;
}

.change-password-btn {
  background: #21ba06;
  border: none;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  font-family: "Montserrat";
}

.change-password-btn:hover {
  cursor: pointer;
  background: #198f04;
}

.account-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-info-text {
  margin: 0 0 0 3px;
  font-weight: 500;
  font-size: 10px;
  color: #a5a5a9;
}

.account-info-eyeball {
    font-size: 15px;
    margin: 0;
    color: #C2C2C7;
    position: relative;
    cursor: pointer;
}

.close-account-details-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 80px;
  height: 30px;
  background: #bababe;
  color: black;
  border: none;
  border-radius: 5px;
  margin: 0 20px 0 0;
}

.close-account-details-btn:hover {
  cursor: pointer;
  background: #97979b;
}

.save-account-details-btn {
  font-family: "Montserrat";
  font-weight: 600;
  width: 80px;
  height: 30px;
  color: white;
  background: #0091ff;
  border-radius: 5px;
  border: none;
}

.save-account-details-btn:hover {
  cursor: pointer;
  background: #0175ce;
}

@media only screen and (max-width: 420px) {
  .account-details-form {
    width: 370px;
    margin-bottom: 60px;
  }

  .input-label-row {
    flex-direction: column;
  }
}
