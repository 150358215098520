.augio-statements-container {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 12px;
  padding: 10px;
  justify-content: center;
  margin: 40px 0 50px 0;
  flex-direction: column;
}

.statements-header {
  font-family: "Akira Expanded", sans-serif;
  font-size: 32px;
  color: white;
  text-align: center;
  margin: 90px 0 0 0;
}

.statement-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
}

.gradient-border {
  padding: 3px;
  border-radius: 12px;
  background: linear-gradient(to right, #aa01d4, #f40f2c);
  width: fit-content;
}

.statement-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  height: 280px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #0e0e0e;
  border-radius: 10px;
}

.statement-container.visible {
  opacity: 1;
}

.statement-header {
  color: #ffffff;
  margin: 0 15px 2px 0;
  font-family: "Akira Expanded", sans-serif;
  font-size: 22px;
}

.statement-bigtext {
  color: #8c8c93;
  margin: 2px 0 2px 0;
  font-family: "Akira Expanded", sans-serif;
  font-size: 20px;
}

.statement-subtext {
  color: #ceced6;
  margin: 2px 0 0 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.link-icon {
  font-size: 24px;
  color: white;
  cursor: pointer;
  transform: rotate(135deg);
  margin: 0;
}

.mobile-chain-link {
  display: none;
}

@media only screen and (max-width: 420px) {
  .augio-statements-container {
    display: flex;
    flex-direction: column;
  }

  .statement-container { 
  width: 370px;
  height: auto;
  }

  .mobile-chain-link {
    display: block;
  }
}
