.copyright-infringement-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  padding: 0 0 100px 0;
}

.augio-logo-link {
  height: 50px;
  margin: 40px auto 20px auto;
}

.copyright-infringement-form {
  display: flex;
  flex-direction: column;
  background: #171717;
  justify-content: center;
  padding: 25px 40px 35px 40px;
  border-radius: 14px;
  box-shadow: 7px 7px 18px 1px rgba(0, 0, 0, 0.056);
  position: relative;
  width: 700px;
}

.copyright-infringement-header-text {
  font-size: 20px;
  margin: 0 0 30px 0;
  font-weight: 600;
}

.copyright-infringement-subtext {
  text-align: left;
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  width: 620px;
  margin: 0 0 30px 0;
  text-align: justify;
}

.copyright-infringement-input {
  margin: 7px 0 10px 0;
  background: #1d1d1d;
  border: 1px solid #333334;
  border-radius: 4px;
  color: white;
  width: auto;
  height: 40px;
  outline: none;
  font-size: 15px;
  font-family: "Noto Sans", sans-serif;
}

.copyright-infringement-input::placeholder {
  color: #6f6f76;
  font-size: 12px;
}

.copyright-infringement-form-label {
  font-weight: 500;
  font-size: 13px;
}

.copyright-infringement-textarea {
  margin: 10px 0 10px 0;
  background: #1d1d1d;
  border: 1px solid #333334;
  border-radius: 4px;
  color: #3d3d40;
  color: #3d3d40;
  width: auto;
  height: 100px;
  outline: none;
  margin: 15px 0 15px 0;
  font-size: 15px;
  resize: vertical;
}

.copyright-infringement-textarea::placeholder {
  color: #6f6f76;
  font-family: "Noto Sans", sans-serif;
}

.infringement-agreements-group {
  margin: 5px 0 20px 0;
}

.infringe-agreement-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.infringe-agreement-checkbox {
  height: 15px;
  width: 50px;
  margin: 0;
}

.infringe-agreement-text {
  width: 600px;
  font-size: 13px;
}

.copyright-infringement-submit {
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  font-family: "Montserrat";
  padding: 8px 25px 8px 25px;
  background-color: #0aaa1d;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin: 20px 0 0 0;
}

.copyright-infringement-submit:hover {
  background-color: #09971a;
}

.copyright-form-error-message {
  padding-bottom: 15px;
  color: red;
  font-size: 12px;
}

.infringement-form-error {
  padding-bottom: 10px;
  color: red;
  font-size: 10px;
  font-weight: 500;
}

.infringement-form-success-message {
  font-size: 13px;
  padding: 3px;
  border-radius: 3px;
  color: green;
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  .copyright-infringement-header-text {
    font-size: 17px;
  }
}
